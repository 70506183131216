@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');

html {
  font-size: 22px;
  margin: 0;
  padding: 0;
  color: #161616;
  font-family: 'Arvo', serif;
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* loginPage */
.App2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.section2 {
  color: black;
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
}

.formcontainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.button {
  width: 120px;
  background-color: #1e40af;
  color: white;
  cursor: pointer;
}

.footers {
  text-align: center;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #1e40af;
  color: white;
  border: 1px solid #BFBFBF;
  box-shadow: 5px -10px 8px 1px rgba(0, 0, 0, 0.07);
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.vipslogo {
  color: black;
  position: absolute;
  top: 1rem;
  left: 2rem;
  z-index: 1;
}

.imgsize {
  width: 60px;
  height: 60px;
}

.outertable {
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.table {
  background-color: #fff;
  font-family: 'Mukta', sans-serif;
  border-collapse: collapse;
  text-align: left;
  color: #2B3B47;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
}

thead {
  box-shadow: 0 5px 10px var(--clr-gray300);
}

tr {
  border-bottom: 1px solid #e7e7e7;
  font-size: 25px;
}

td {
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  /* min-width: 140px; */
}

th {
  padding: 10px 0;
  text-align: center;
  /* font-family: Lato-Bold; */
  font-size: 18px;
  color: #fff;
  line-height: 1.4;
  letter-spacing: 0.1rem;
  background: rgba(7, 29, 84, 0.9);
  min-width: 90px;
  font-weight: unset;
}







a {
  text-decoration: none;
  color: #161616;
}

.getstarted {
  position: absolute;
  display: none;
  top: 80vh;
  left: 45vw;
  z-index: 99;
  /* bottom: calc(60px + (env(safe-area-inset-bottom))*1.1); */
}

.bottomnavigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: fixed;
  bottom: 0;
  /* left: 0; */
  width: 100vw;
  padding: 5px 5vw;
  background: white;
  z-index: 9999;
}




.menuItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  height: 2.5rem;
  position: relative;
  transition: all 300ms ease;
  font-size: 16px;
}

.menuItem:hover {
  cursor: pointer;
}

.active {
  scale: 1.2;
  color: #780f0f;
}







.new {
  min-height: 90vh;
  background-color: #f5f5f7;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.favouriteshead{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    color: #ef4444;
    margin-top: 10px;
}

.cardcontainer {
  width: 90%;
  margin: 1rem auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
}

.cardcontainer2 {
  margin: 1rem auto;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding-bottom: 3rem;
}


.newinput {
  font-size: 22px;
  padding: .25rem;
}

.allcustomerinput {
  width: 250px;
  box-sizing: border-box;
  border: 1px solid white;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
  margin: 10px auto 0;
}

.Cards {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  height: 120px;
  background: white;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%);
}

.Cards:hover{
  box-shadow: 0px 4px 4px rgb(134, 126, 245);
}



.Cards2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 40vw;
  height: 50%;
  background: #f8fafc;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.parentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parentContainer2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.parentContainer3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.parentContainer4{
  display: flex;
  align-items: center;
}



/* settingpage */

.app2 {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.settingheader {
  margin-top: 3rem;
  margin-left: 2rem;
  color: #c34343;
}

.settingbody {
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}

.personalinfo {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
  gap: 1.5rem;
  background: #e4e6c4;
  border-radius: 20px;
  box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%);
  padding: 8px;
  width: 70vw;
  max-width: 300px;
  cursor: pointer;
}

.icondiv {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #d1d1e1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icondiv2 {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #79df98;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icondiv3 {
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: #eec3cb;
  display: flex;
  justify-content: center;
  align-items: center;
}





.titlediv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3px;
}

.settingpage {
  position: relative;
}
.googlemap{
  margin: 0 auto;
  position: relative;
}


/* detailPage */
.app3 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 4rem;
  align-items: center;
}

.detailCard{
  position: absolute;
  top: 40vh;
  z-index: 999;
  background-color: white;
  width: 90vw;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 4rem;
}

.detailheaderdiv{
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5%;
  margin: 10px;
}

.detailheader {
  font-size: 30px;
}

.detaildesp{
  padding: 10px 13%;
}

.read-or-hide{
  font-size: 16px;
  cursor: pointer;
  color: rgb(134, 59, 59);
}

.visitpp{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 5%;
}

.visit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.visitdiv{
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  min-width: 50px;
  padding: 0 10px;
  border-radius: 10px;
  background: rgba(255, 242, 233, 0.8);
  color: rgb(159, 106, 11);
  display: flex;
  justify-content: center;
  align-items: center;
}

.purchasediv{
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  min-width: 50px;
  padding: 0 10px;
  border-radius: 10px;
  background: rgba(120, 237, 160, 0.8);
  color: rgb(14, 147, 59);
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointsdiv{
  font-size: 17px;
  font-weight: 500;
  height: 40px;
  min-width: 50px;
  padding: 0 10px;
  border-radius: 10px;
  background-color: #d1d1e1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fontvis{
  font-size: 18px;
}

.tabs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5%;
}

.Cards4 {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 90vw;
  height: 150px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  justify-content: center;
  align-items: center;
  gap: 20%;
}

.Cards3 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90vw;
  margin: 1rem auto;
  padding: 16px 10px;
  background: #f8fafc;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.checkInSubmit4 {
  border-radius: 10px;
  height: 55px;
  color: white;
  background: #102149;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  padding: 6px 2rem;
  color: white;
  }

  .loadingText{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 5px;
  }

  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 1000px;
    height: 1800px;
    margin-left: 50%;
    margin-top: 20vh;
}

.lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: green;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
}

@keyframes lds-facebook {
    0% {
        top: 8px;
        height: 64px;
    }

    50%,
    100% {
        top: 24px;
        height: 32px;
    }
}






@media screen and (max-width: 1000px) {
  .Cards2 {
    width: 65%;
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  .cardcontainer {
    grid-template-columns: auto;
  }
  .cardcontainer2 {
    grid-template-columns: auto;
  }

  .Cards2 {
    width: 70%;
    height: 50%;
  }
}

@media screen and (max-width: 600px) {
  .getstarted {
    position: absolute;
    display: none;
    top: 80vh;
    left: 30vw;
    z-index: 99;
  }

  .logoimage {
    display: none;
  }

  .Cards2 {
    width: 80%;
    height: 50%;
  }
}